import React from 'react'

function DashboardTab() {
  return (
    <div>
      Dashboard
    </div>
  )
}

export default DashboardTab
