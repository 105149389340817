import React, { useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Tab, Tabs, NavDropdown } from "react-bootstrap";
import DashboardTabs from "./DashboardTabs";
import DashboardTab from "./DashboardTabs/DashboardTab";

function Dashboard() {

  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <Header />
      <section
        className="page-header-section ptb-100 bg-image"
        image-overlay="7"
      >
        <div
          className="background-image-wraper"
          style={{
            background: `url('/assets/img/vission&mission-banner.jpg')`,
            opacity: "1",
          }}
        ></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-9 col-lg-7">
              <div className="page-header-content text-white pt-4">
                <h1 className="text-white mb-0">Dashboard</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="">
        {/* <Tabs defaultActiveKey="dashboard" id="uncontrolled-tab-example" className="mb-3 dashboard-tabs">
          <Tab eventKey="dashboard" title="Dashboard">
            Dashboard Content
          </Tab>
          <Tab eventKey="items2" title="Items 2">
            Items 2 Content
          </Tab>
          <Tab eventKey="item3" title="Item 3">
            Item 3 Content
          </Tab>

       
          <NavDropdown
            title="Dropdown"
            id="nav-dropdown"
            show={dropdownOpen} 
            onClick={handleDropdownClick} 
          >
            <NavDropdown.Item eventKey="dropdown1">Dropdown Item 1</NavDropdown.Item>
            <NavDropdown.Item eventKey="dropdown2">Dropdown Item 2</NavDropdown.Item>
            <NavDropdown.Item eventKey="dropdown3">Dropdown Item 3</NavDropdown.Item>

           
            <NavDropdown.Divider />
            <NavDropdown.Item as="div">
              <Tabs
                activeKey={nestedTabKey}
                onSelect={(k) => setNestedTabKey(k)}
                className="nested-tabs"
              >
                <Tab eventKey="nested1" title="Nested Tab 1">
                  Nested Tab 1 Content
                </Tab>
                <Tab eventKey="nested2" title="Nested Tab 2">
                  Nested Tab 2 Content
                </Tab>
                <Tab eventKey="nested3" title="Nested Tab 3">
                  Nested Tab 3 Content
                </Tab>
              </Tabs>
            </NavDropdown.Item>
          </NavDropdown>
        </Tabs> */}
      </div>

      <div>
        <DashboardTabs
          onTabSelect={handleTabSelect}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {selectedTab === "Dashboard" && <DashboardTab />}
        {selectedTab === "Comparative Nutrients" && "Comparative Nutrients"}
      </div>

      <Footer />
    </div>
  );
}

export default Dashboard;
