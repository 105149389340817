import React, { useEffect } from "react";
import Header from "../Components/Header";
import "../Pages/Main.css";
import Footer from "../Components/Footer";
import Newsletter from "../Components/Newsletter";
import Counter from "../LabSoftware/Counter";
import Faqs from "../LabSoftware/Faqs";
import Testimonial from "../LabSoftware/Testimonial";
import WorkProcess from "../LabSoftware/WorkProcess";
import Features from "../LabSoftware/Features";
import LatestBlogs from "../LabSoftware/LatestBlogs";
import PricingTableSecond from "../Components/PricingTableSecond";
import LarisBannerSlider from "../LabSoftware/LarisBannerSlider";

function LabSoftware() {
  var BreadCrumbTitle = "LARIS :: D-Right Technologies";
  document.title = BreadCrumbTitle;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="main">
        {/* 
      <section className="ptb-80 hero-bg-shape laris_bg-wrapper" style={{backgroundImage: "url('/assets/img/hero-bg-shape-1.svg')"}}>
            
        <LarisBannerSlider />
        </section> */}

        <LarisBannerSlider />
        {/* <section
          className="position-relative bg-image pt-100"
          image-overlay="8"
        >
          <div
            className="background-image-wraper"
            style={{
              background: `url('/assets/img/hero-bg-shape-1.svg')`,
              opacity: 1,
            }}
          ></div>

          <div className="shape-bottom">
            <img
              src="assets/img/curve-shape-top.svg"
              alt="shape"
              className="bottom-shape img-fluid"
            />
          </div>
        </section> */}

        {/*<Features/>*/}

        <section id="about" className="about-new-section ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12 col-lg-7 pr-lg-5 order-md-1 order-lg-0">
                        <div className="image-wrap">
                                <img src="assets/img/about-laris.png" alt="about" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-5 mb-5 mb-md-5 mb-lg-0">
                        <div className="about-content-wrap">
                            <h2>Streamline Your Lab Workflow with LARIS</h2>
                            <p>LARIS, a cutting-edge pathology laboratory software meticulously crafted to streamline workflow and optimize efficiency. Launched in Pakistan by D-Right Technologies (Pvt.) Ltd. in collaboration with ClinTa Healthcare Technologies (CHT), USA.</p>
                            <p>It caters to the diverse needs of phlebotomists, technologists, pathologists, and administrators, providing tailored solutions for seamless operation and enhanced productivity in pathology labs across Pakistan. </p>
                            {/*<ul className="list-inline text-counter-list">
                                <li className="list-inline-item my-2">
                                    <h3 className="mb-1 font-weight-bolder color-accent">50K</h3>
                                    <span>Our Customer</span>
                                </li>
                                <li className="list-inline-item my-2">
                                    <h3 className="mb-1 font-weight-bolder color-accent">20K</h3>
                                    <span>Total Install</span>
                                </li>
                                <li className="list-inline-item my-2">
                                    <h3 className="mb-1 font-weight-bolder color-accent">120</h3>
                                    <span>Project Running</span>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative feature-section ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-12 col-lg-6">
                <div className="feature-contents section-heading">
                  <h2 className="web">Advantages of LARIS</h2>
                  <p>
                  Discover how our integrated approach, unified workflows, and synchronized outcomes elevate every aspect of your laboratory operations.
                  </p>
                  <ul className="dot-circle pt-2">
                    <li>
                    <b>Integrated Sections:</b> LARIS connects all lab departments, from sample collection to report delivery, enhancing efficiency with dedicated dashboards for every role.
                    </li>
                    <li>
                    <b>Unified Workflow:</b> Each segment of your lab works in sync, ensuring smooth operations and optimal resource management without disruptions.
                    </li>
                    <li>
                    <b>Synchronized Outcome:</b> LARIS ensures fast, accurate results while boosting profitability through efficient and synchronized lab operations.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="download-img">
                  <img
                    src="/assets/img/advantages-laris.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="feature-section ptb-100">
            <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-lg-8">
                            <div className="section-heading text-center mb-5">
                                <h2>Integrated Segments in LARIS</h2>
                                <p>LARIS offers 11 dedicated and independent segments, each meticulously designed to function seamlessly together, providing a well-integrated solution that enhances overall efficiency and collaboration across different operational areas.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/receptionist.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">RECEPTIONIST  </h5>
                                            <p>Efficiently manage patient appointments, registrations, and inquiries with streamlined tools to enhance front-desk operations and improve patient experience.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/technologist.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">TECHNOLOGIST </h5>
                                            <p>Enhance laboratory testing and analysis processes with advanced modules that facilitate accurate, timely, and efficient workflow management for lab technologists.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/inventory.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">INVENTORY </h5>
                                            <p>Optimize inventory control with precise tracking of stock, automated reorder alerts, and comprehensive reporting to ensure laboratory supplies are always available.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/procurement.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">PROCUREMENT </h5>
                                            <p>Streamline the procurement process with tools for managing supplier relationships, ordering laboratory supplies, tracking inventory levels, and ensuring timely replenishment.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                            <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/phlebotomist.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">PHLEBOTOMIST </h5>
                                            <p>Simplify sample collection and tracking with dedicated features that ensure accurate labeling, scheduling, and management of blood draws and other sample types.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/HumanResource.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">HUMAN RESOURCE </h5>
                                            <p>Facilitate HR management with modules for employee records, payroll, performance evaluations, and compliance with labor regulations, ensuring smooth HR operations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/admin.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">ADMIN </h5>
                                            <p>Oversee the entire laboratory operation with centralized admin controls, enabling effective policy implementation, compliance monitoring, and performance tracking.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/patients.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">PATIENTS </h5>
                                            <p>Empower patients with automated report delivery via email, SMS alerts, and a versatile patient portal offering a personalized dashboard for health records and test results.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/accounts.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">ACCOUNTS  </h5>
                                            <p>Manage financial transactions, billing, and accounting tasks with ease using integrated features designed to handle invoices, payments, and financial reporting.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/pathologist.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">PATHOLOGIST </h5>
                                            <p>Provide comprehensive tools for pathologists to review, diagnose, and report findings, with secure access to patient data and integration with lab results.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/ClientLabs.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">CLIENT LABS </h5>
                                            <p>Provide a seamless interface for collaborating with third-party client labs, enabling secure data exchange, report sharing, and coordinated testing services.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

        {/*<section id="about" className="about-new-section ptb-100" >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12 col-lg-5 mb-5 mb-md-5 mb-lg-0">
                        <div className="about-content-wrap">
                            <h2>Discover LARIS Tailored Solutions for Every Lab</h2>
                            <p>Explore our three editions of LARIS software, each designed to meet the unique needs of pathology laboratories. From essential features in the Basic Edition to higher level functionalities in the Premium Edition, LARIS offers scalable solutions to optimize lab operations and drive efficiency.</p>
                            <p>Choose the edition that best fits your lab's requirements and unlock the full potential of pathology management. </p>
                            <ul className="list-inline text-counter-list">
                                <li className="list-inline-item my-2">
                                    <h3 className="mb-1 font-weight-bolder color-accent">50K</h3>
                                    <span>Our Customer</span>
                                </li>
                                <li className="list-inline-item my-2">
                                    <h3 className="mb-1 font-weight-bolder color-accent">20K</h3>
                                    <span>Total Install</span>
                                </li>
                                <li className="list-inline-item my-2">
                                    <h3 className="mb-1 font-weight-bolder color-accent">120</h3>
                                    <span>Project Running</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-7 pr-lg-5 order-md-1 order-lg-0">
                        <div className="image-wrap">
                        <img src="/assets/img/discover-laris.png" alt="features" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        {/*<WorkProcess /> */}

        {/* <PriceTable/> */}

        <LatestBlogs />

        {/* <PricingTableSecond product="lab" /> */}

        {/* <Counter /> */}

        {/* <section className="team-two-section ptb-100 ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center">
                  <h2>Our Team Members</h2>
                  <p>
                    Authoritatively mesh intuitive paradigms vis-a-vis
                    goal-oriented partnerships. Continually extend open-source
                    outside the box thinking after focused catalysts.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="/assets/img/team/team-member-1.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Richard Ford</h5>
                    <span>Instructor of Mathematics</span>
                    <p className="mt-3">
                      Authoritatively engage leading-edge processes tactical
                      capital{" "}
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="/assets/img/team/team-member-2.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Kely Roy</h5>
                    <span>Lead Designer</span>
                    <p className="mt-3">
                      Monotonectally engage sticky collaborative markets
                      synergistically
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="/assets/img/team/team-member-3.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Gerald Nichols</h5>
                    <span>Managing Director</span>
                    <p className="mt-3">
                      Assertively procrastinate standardized whereas technically
                      sound
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="/assets/img/team/team-member-4.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Gerald Nichols</h5>
                    <span>Team Manager</span>
                    <p className="mt-3">
                      Synergistically actualize out the-box technologies before
                      parallel process
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <Testimonial /> */}

        {/* <section id="contact" className="contact-us-section ptb-100">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-12 pb-3 message-box d-none">
                <div className="alert alert-danger"></div>
              </div>
              <div className="col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                <div className="contact-us-form gray-light-bg rounded p-5">
                  <h4>Ready to get started?</h4>
                  <form
                    action="#"
                    method="POST"
                    id="contactForm"
                    className="contact-us-form"
                  >
                    <div className="form-row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter name"
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter email"
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            rows="7"
                            cols="25"
                            placeholder="Message"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-3">
                        <button
                          type="submit"
                          className="btn btn-brand-02"
                          id="btnContactUs"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="contact-us-content">
                  <h2>Looking for a excellent Business idea?</h2>
                  <p className="lead">
                    Give us a call or drop by anytime, we endeavour to answer
                    all enquiries within 24 hours on business days.
                  </p>

                  <a
                    href="#"
                    className="btn btn-outline-brand-01 align-items-center"
                  >
                    Get Directions <span className="ti-arrow-right pl-2"></span>
                  </a>

                  <hr className="my-5" />

                  <ul className="contact-info-list">
                    <li className="d-flex pb-3">
                      <div className="contact-icon mr-3">
                        <span className="fas fa-location-arrow color-primary rounded-circle p-3"></span>
                      </div>
                      <div className="contact-text">
                        <h5 className="mb-1">Company Location</h5>
                        <p>100 Yellow House, Mn Factory, United State, 13420</p>
                      </div>
                    </li>
                    <li className="d-flex pb-3">
                      <div className="contact-icon mr-3">
                        <span className="fas fa-envelope color-primary rounded-circle p-3"></span>
                      </div>
                      <div className="contact-text">
                        <h5 className="mb-1">Email Address</h5>
                        <p>hello@yourdomain.com</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
      <Faqs />
      <Newsletter />
      <Footer />
    </>
  );
}

export default LabSoftware;
