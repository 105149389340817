import React, { useEffect } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LuLayoutDashboard } from "react-icons/lu";

function DashboardTabs({ onTabSelect, selectedTab, setSelectedTab }) {
  const handleNavItemClicked = (heading) => {
    onTabSelect(heading);
    // setOpenDropdown(null);
    setSelectedTab(heading); // Close the dropdown when a tab is clicked
  };
  useEffect(() => {
    const navLink = document.querySelector(
      ".dashboard-tabs .dropdown .nav-link"
    );
    const activeDropdownItem = document.querySelector(".dropdown-item.active");

    if (activeDropdownItem) {
      navLink.style.background = "linear-gradient(75deg, #0c2888, #6294f7)";
      navLink.style.color = "white";
    } else {
      navLink.style.background = ""; // Reset to original style
    }
  }, [selectedTab]);
  return (
    <div>
      <div className="dashboard-tabs">
        <Navbar bg="" expand="lg" variant="dark">
          <Navbar.Toggle aria-controls="main_nav" />
          <Navbar.Collapse id="main_nav">
            <Nav className="p-3">
              <Nav.Link
                className={selectedTab === "Dashboard" ? "active" : ""}
                onClick={() => handleNavItemClicked("Dashboard")}
              >
               <LuLayoutDashboard /> Dashboard
              </Nav.Link>
              <NavDropdown title="Pages" id="treeview-menu" className="m-0">
                {/* <NavDropdown
                  title="Diet Facts"
                  id="submenu-2"
                  className="submenu"
                  menuAlign="right"
                >
                  <NavDropdown.Item
                    className={selectedTab === "fruits" ? "active" : ""}
                    onClick={() => handleNavItemClicked("fruits")}
                  >
                    Fruits
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className={selectedTab === "vegetables" ? "active" : ""}
                    onClick={() => handleNavItemClicked("vegetables")}
                  >
                    Vegetable
                  </NavDropdown.Item>
                </NavDropdown> */}
                <NavDropdown.Item
                  className={
                    selectedTab === "Comparative Nutrients" ? "active" : ""
                  }
                  onClick={() => handleNavItemClicked("Comparative Nutrients")}
                >
                  Item 1
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}

export default DashboardTabs;
