import React, { useEffect, useState } from "react";
import Newsletter from "../Components/Newsletter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useSelector } from "react-redux";
import PricingTableSecond from "../Components/PricingTableSecond";
import WebBuilderSlider from "../LabSoftware/WebBuilderSlider";
import { CgEnter } from "react-icons/cg";
import { FaDisplay } from "react-icons/fa6";
import {
  BsSpeedometer,
  BsFileEarmarkArrowUp,
  BsFilePerson,
  BsHeartPulse,
  BsCheck2Circle,
  BsChatRightText,
  BsCalendar4Week,
  BsArchive,
} from "react-icons/bs";
import { IoImagesOutline, IoChatbubblesOutline } from "react-icons/io5";
import { BiSupport } from "react-icons/bi";
import { FaChalkboardTeacher, FaUserLock } from "react-icons/fa";
import { RiNumber1 } from "react-icons/ri";
function WebBuilder() {
  const branding = useSelector((state) => state.branding.value);
  useEffect(() => {
    console.log("branding", branding);
  }, [branding]);
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqData = [
    {
      question: "What is a web builder, and why do I need one?",
      answer:
        "A web builder is a tool that allows you to create websites without needing to code. You need one to easily design and publish a professional-looking website for your business or personal use",
    },
    {
      question: "Do I need any technical skills to use ClinTa Web-Builder?",
      answer:
        "No, ClinTa Web-Builder  is designed for users of all skill levels. It features a user-friendly interface that allows you to create and customize your website without any coding knowledge.",
    },
    {
      question: "Can I customize the design of my website?",
      answer:
        "Absolutely! ClinTa Web-Builder offers a wide range of customizable templates, colors, fonts, and layouts, allowing you to create a unique website that reflects your brand identity.",
    },
    {
      question: "Is the website mobile-friendly?",
      answer:
        "Yes, all websites created with ClinTa Web-Builder are automatically optimized for mobile devices, ensuring a seamless user experience across smartphones, tablets, and desktops.",
    },
    {
      question: "Can I integrate third-party tools to my site?",
      answer:
        "Yes, ClinTa Web-Builder supports integration with various third-party tools and widgets, such as social media buttons, to enhance your website's functionality.",
    },
    {
      question: "Is there customer support for issues?",
      answer:
        "Of course! We offer dedicated customer support to assist you with any questions or technical issues you may encounter while using ClinTa Web-Builder. Simply reach out to our support team for assistance.",
    },
    {
      question: "How can I publish my website once it's ready?",
      answer:
        "Publishing your website is as simple as clicking a button. ClinTa Web-Builder provides easy-to-follow instructions for publishing your website to your domain or hosting platform.",
    },
    {
      question: "Is there a limit on adding website banners?",
      answer:
        "No, there is no limit to the number of banners you can create on your website with ClinTa Web-Builder. You can add as many banners as you need to showcase your content and services.",
    },
    {
      question: "Can I track the performance of my website with analytics?",
      answer:
        "Yes, ClinTa Web-Builder allows you to integrate with popular analytics tools like Google Analytics to track your website's performance, including traffic, user behavior, and conversions.",
    },
    {
      question: "Is my website's data secure?",
      answer:
        "Yes, we take security seriously. Your website's data is encrypted and stored securely on our servers, and we regularly update our security measures to protect against cyber threats and data breaches.",
    },
    {
      question: "What happens if I decide to cancel my subscription?",
      answer:
        "If you choose to cancel your subscription, you can copy your website's content and data to ensure future use. Additionally, you can always re-subscribe to access your website and continue editing it.",
    },
    {
      question:
        "How Web-Builder (For Doctors) benefits clinical practice?",
      answer:
        "The ClinTa Web-Builder  (Doctor version) is tailored to meet the specific needs of medical professionals, allowing you to create a professional website that showcases your expertise, services, and patient care philosophy.",
    },
    {
      question: "Can I include patient education materials on my website?",
      answer:
        "Yes, you can easily integrate patient education materials into your website using  ClinTa Web-Builder intuitive tools and features.",
    },

    {
      question: "Is there a feature to schedule appointments online?",
      answer:
        "Absolutely! Our Web-Builder  offers an appointment scheduling feature that allows patients to book appointments online, helping you streamline your practice's scheduling process.",
    },
    {
      question: "Can I showcase patient testimonials on my website?",
      answer:
        "Yes, you can display patient testimonials and reviews on your website to build trust and credibility with prospective patients. Our Web-Builder provides easy-to-use tools for adding testimonials to your site.",
    },
    {
      question: "How its hospital version enhances our online presence?",
      answer:
        "The ClinTa Web-Builder  (Hospitals version)  empowers your institution to create a dynamic online presence that highlights your facilities, medical specialties, and commitment to patient care, helping you stand out in the competitive healthcare landscape.",
    },
    {
      question: "Is patient portal secure for EMRs and appointments?",
      answer:
        "Yes, ClinTa Web-Builder  (Hospital version) offers a secure patient portal feature that allows patients to access their medical records, schedule appointments, and communicate with healthcare providers online, enhancing patient engagement and satisfaction.",
    },
    {
      question: "Can I show accreditations and awards on website?",
      answer:
        "Yes, you can prominently display your hospital's accreditations, certifications, and quality awards on your website to showcase your commitment to excellence in healthcare delivery and patient safety.",
    },
    {
      question: "How its Pathology version benefits lab's online presence?",
      answer:
        "Our ClinTa Web-Builder (Pathology version) is designed to help your lab establish a professional online presence, allowing you to showcase your diagnostic services, advanced technology, and commitment to precision to attract more clients and patients.",
    },
    {
      question: "Can I mention the lab's test and service range?",
      answer:
        "Yes, you can provide detailed information about the various tests and services offered by your pathology laboratory, including descriptions, test panels, specimen requirements, and turnaround times, to help potential clients and patients understand your capabilities.",
    },
    {
      question: "Is its Pathology version secure to deliver test results?",
      answer:
        "Yes, ClinTa Web-Builder  (Pathology version) offers a secure online test results delivery feature that allows patients to access their test results securely and conveniently through a patient portal, ensuring patient privacy and confidentiality. It can be done if you are using ClinTa LARIS.",
    },
    {
      question: "Can we integrate with EMR systems for data exchange?",
      answer:
        "Yes, ClinTa Web-Builder conditionally offers integration options with electronic medical record (EMR) systems, allowing for seamless data exchange and interoperability with healthcare providers' systems, streamlining workflows and improving efficiency.",
    },

    {
      question: "How is it specialized for healthcare professionals?",
      answer:
        "ClinTa Web-Builder is specifically designed to meet the unique needs and requirements of healthcare professionals. We understand the importance of compliance, security, and patient confidentiality in the healthcare industry, and our platform is tailored to address these concerns.",
    },
    {
      question: "What special does it offer to healthcare professionals?",
      answer:
        "ClinTa Web-Builder offers a range of specialized, secure patient portals for online communication and appointment scheduling, integration with electronic health records (EHR) systems, and customizable templates designed specifically for healthcare practices.",
    },
    {
      question: "How does it ensure compliance with institutions like IHRA?",
      answer:
        "We take compliance seriously and have implemented strict security measures to ensure that our platform meets IHRA & PHC and all other regulatory authorities’ requirements. Our infrastructure is built to provide end-to-end encryption, access controls, and regular security audits to protect sensitive patient information.",
    },

    {
      question: "Does it provide healthcare practices' branding?",
      answer:
        "Yes, absolutely! Our web builder offers customizable templates and design options that allow healthcare practices to showcase their unique brand identity and services. Whether you're a small clinic or a large hospital, our platform can be tailored to suit your specific needs and preferences.",
    },

    {
      question:
        "How Web-Builder supports online patient interaction?",
      answer:
        "ClinTa Web-Builder includes features such as secure patient portals, online appointment scheduling, telemedicine capabilities, and patient education resources to enhance communication and engagement between healthcare providers and patients. This helps improve patient satisfaction and outcomes.",
    },

    {
      question: "What distinguishes your healthcare-focused web builder?",
      answer:
        "Unlike general business web builders, our platform is built from the ground up with the healthcare industry in mind. We understand the unique challenges and requirements of healthcare providers, and our platform offers specialized features and compliance measures specifically tailored to address these needs.",
    },
  ];

  var BreadCrumbTitle = "Web Builder :: D-Right Technologies";
  document.title = BreadCrumbTitle;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [expandedIndex, setExpandedIndex] = useState(null);
  // const toggleExpandReview = (index) => {
  //   setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  // };

  const handleClick = (index, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    console.log("handleClick called with index:", index); // Debugging
    toggleExpandReview(index);
  };

  const toggleExpandReview = (index) => {
    console.log("toggleExpandReview called with index:", index); // Debugging
    setExpandedIndex((prevIndex) => {
      console.log("Previous index:", prevIndex); // Debugging
      return prevIndex === index ? null : index;
    });
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const promosData = [
    {
      // icon: "/assets/img/icon/icon-1.svg",
      icon: "/assets/img/admin-panel-img.png",
      title: "Admin Panel",
      content:
        "It offers an Advanced Admin Panel that goes beyond basic website customization, which enables to effortlessly manage images, colors, text, and banners to maintain a vibrant and dynamic online presence. It additionally offers a Data Management System, allowing you to efficiently handle all records and communication related to patients, doctors, and job applicants.  ",
    },

    {
      // icon: "/assets/img/icon/icon-1.svg",
      icon: "/assets/img/economical-img.png",
      title: "Economical",
      content:
        "It offers a vast array of features at a fraction of the cost of custom-built website. By serving a large number of clients, we achieve economies of scale that individual developers cannot match. Custom development requires extensive time and expertise, making it prohibitively expensive. Hence, cost effectiveness of our product is unparalleled.",
    },

    {
      icon: "/assets/img/scalability-img.png",
      title: "Scalable",
      content:
        "It offers scalability to accommodate your preference for what to show and what not to show to the visitors of your website, it allows you to easily expand your website’s functionality, and add new features, which make it a versatile platform for building dynamic, engaging and a comprehensive website.",
    },

    {
      // icon: "/assets/img/icon/icon-1.svg",
      icon: "/assets/img/user-freinedly-img.png",
      title: "User-Friendly",
      content:
        "It offers user friendly interface that allows individuals to create and customize a website without any coding knowledge or technical skills. Intuitive drag and drop tools, pre-designed templates, and simple editing options make the website-building process easy for everyone. Its data management system is also very easy to understand and operate.",
    },
    {
      icon: "/assets/img/customizable-img.png",
      title: "Customizable",
      content:
        "The ability to personalize the website’s design, layout, and content according to individual preferences is a key feature. Customization options, including diverse templates, multiple banners, color schemes, images, fonts, and multimedia integration, allow users to create an appealing website. Its customization advantages are unique and cost effective.",
    },
    {
      icon: "/assets/img/responsive-img.png",
      title: "Responsive",
      content:
        "Ensuring website looks and functions seamlessly across various devices, including desktops, laptops, tablets, and smartphones, is crucial. A responsive design feature automatically adjusts the layout and content of the website to provide an optimal viewing experience on different screen sizes and resolutions.",
    },
    {
      icon: "/assets/img/intigrated-img.png",
      title: "Integrated",
      content:
        "It integrates with the entire ClinTa range of software programs offering patient access to electronic medical records (EMRs) and all other pathology lab reports on the password protected patient portal given on the website built through web builder. It also integrates appointment panel with hospital software for integrated invoicing.",
    },
    {
      icon: "/assets/img/interactive-img.png",
      title: "Interactive",
      content:
        "It offers interactive features such a secure messaging, appointment, general public feedback, job posting, CV management & interview scheduling. It also offers tele-consultation through e-health module. It also empowers patients with a dedicated portal for accessing integrated relevant data, including test results, medical records, and appointment scheduling.",
    },
    {
      icon: "/assets/img/infromative-img.png",
      title: "Informative",
      content:
        "It provides healthcare information for the community, accessible through the patient portal. It offers valuable resources such as diet facts, healthcare calculators, and home remedies to promote health literacy and wellness education among general public. Comparative nutrient analysis is a unique feature introduced to promote healthy dietary habits.",
    },

    {
      icon: "/assets/img/ssl-certificate-img.png",
      title: "SSL Certified",
      content:
        "It inspires trust and confidence among users by displaying the SSL certificate, indicating a secure connection and protecting against potential cyber threats: ensuring data integrity and confidentiality. Moreover, it can boost our website search engine ranking. We are committed to provide secure website to all our clients.",
    },
    {
      icon: "/assets/img/data-encrpted-img.png",
      title: "Encrypted",
      content:
        "We are fully aware of the sensitivity of privacy of healthcare data of patients  visiting a hospital, hence we utilize advanced encryption algorithms to transform information into unreadable ciphertext, protecting it from unauthorized access. It Provides a secure environment for storing and managing sensitive data.",
    },
  ];

  const features = [
    {
      icon: <FaDisplay className="twox" />,
      title: "Display",
      description:
        "The front end of ClinTa Web-Builder ensures an engaging and informative experience for visitors. It showcases hospitals, doctors, and labs with attractive profiles, highlighting facilities and services offered in an attractive manner.",
    },
    {
      icon: <BsSpeedometer className="twox" />,
      title: "Dashboard",
      description:
        "Whether an administrator or a visitor, our dedicated dashboard provides easy access to essential features and information. Seamlessly navigate through your website or manage content with built-in controls.",
    },
    {
      icon: <FaUserLock className="twox" />,
      title: "Access Levels",
      description:
        "With multiple administrators supported by ClinTa Web-Builder, you can assign varying levels of authority and control. This feature ensures efficient management of website operations and content moderation.",
    },
    {
      icon: <BsHeartPulse className="twox" />,
      title: "E-Health",
      description:
        "Access premium teleconsultation with e-Health, designed for top-tier clients. Experience seamless doctor-patient interactions, detailed consultation notes, and two-way communication for superior healthcare.",
    },
    {
      icon: <FaChalkboardTeacher className="twox" />,
      title: "Profiling",
      description:
        "Hospitals, laboratories, and doctors can create personalized profiles to showcase their expertise and services effectively. Present yourself professionally to visitors and establish credibility within the healthcare community.",
    },
    {
      icon: <BsCheck2Circle className="twox" />,
      title: "Permissions",
      description:
        "It enables to take full control of your website's content visibility with customizable permissions. Decide what information is accessible to visitors, ensuring a tailored and secure browsing experience.",
    },
    {
      icon: <BsChatRightText className="twox" />,
      title: "Patient Reviews",
      description:
        "Gather authentic feedback from visitors through patient reviews. Moderated by website owners, these reviews enhance transparency and credibility while preventing malicious content.",
    },
    {
      icon: <BsCalendar4Week className="twox" />,
      title: "Appointment Panel",
      description:
        "Streamline appointment scheduling with our versatile appointment panel, offering slot-based or approval-dependent booking for enhanced convenience and patient satisfaction.",
    },
    {
      icon: <BsArchive className="twox" />,
      title: "Patient Archives",
      description:
        "Keep track of appointment bookings with a comprehensive patient archives feature. Maintain organized logs for efficient record-keeping and management.",
    },
    {
      icon: <IoImagesOutline className="twox" />,
      title: "Gallery",
      description:
        "Showcase hospital events, doctor achievements, and laboratory highlights with an engaging gallery feature. Captivate visitors with visual storytelling and create a sense of being a vital and integral community component.",
    },
    {
      icon: <IoChatbubblesOutline className="twox" />,
      title: "SMS Services",
      description:
        "Keep in touch with patients through personalized and group messaging options. Streamline administrative and marketing efforts with customizable messages, group messaging, and scheduled communications.",
    },
    {
      icon: <BsFileEarmarkArrowUp className="twox" />,
      title: "Digital Archives",
      description:
        "Empower doctors with a dedicated platform for research and academic work. Save, group, highlight, and retrieve academic and research data seamlessly to support professional growth and knowledge sharing.",
    },
    {
      icon: <BsFilePerson className="twox" />,
      title: "CV Builder",
      description:
        "Exclusive to doctors, our CV builder simplifies the creation of professional CVs in various formats. Seamlessly integrate web profile information with a click of a button, streamlining and optimizing the CV creation process.",
    },
    {
      icon: <BiSupport className="twox" />,
      title: "Support",
      description:
        "Access comprehensive support resources, from instructional videos to direct assistance from our team. Enjoy peace of mind as technical issues are promptly resolved, ensuring a seamless website-building experience.",
    },
  ];

  return (
    <>
      <Header />

      <div className="main">
        {/* <section
          className="position-relative ptb-100"
          style={{
            background: `url('/assets/img/home-shape-bg.png')center center`,
            height: "auto",
          }}
        >
          <div className="fit-cover background-image-wraper position-absolute"></div>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="section-heading py-5">
                  <h1>Weh Builder</h1>
                  <p className="lead">
                    Start working with that can provide everything you need to
                    generate awareness, drive traffic, connect. Efficiently
                    transform granular value with client-focused.
                  </p>
                  <div className="action-btns mt-3">
                    <a href="#" className="btn btn-brand-03 btn-rounded mr-3">
                      Download Now{" "}
                      <i className="fas fa-cloud-download-alt pl-2"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/watch?v=1APwq1df6Mw"
                      className="popup-youtube btn btn-white btn-circle btn-icon"
                    >
                      <i className="fas fa-play"></i>{" "}
                    </a>{" "}
                    <span className="pl-2">Watch Now</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="hero-animation-img">
                  <img
                    className="img-fluid"
                    src="assets/img/hero-new-img-2.svg"
                    alt="animation image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section
          className="position-relative bg-image pt-100"
          image-overlay="8"
        >
          <div
            className="background-image-wraper"
            style={{
              background: `url('/assets/img/header-img2.jpg')`,
              opacity: 1,
            }}
          ></div>
          <WebBuilderSlider />

          <div className="shape-bottom">
            <img
              src="assets/img/curve-shape-top.svg"
              alt="shape"
              className="bottom-shape img-fluid"
            />
          </div>
        </section>
        <section className="promo-block ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-md-10 col-lg-10 mb-4"
                style={{ textAlign: "center" }}
              >
                <h2 className="web">Why to Choose ClinTa Web-Builder?</h2>
                <p>
                Experience seamless website creation with integrated EMRs, interactive patient portals, SSL certification, encrypted data, and scalable solutions tailored for healthcare professionals and institutions.
                </p>
              </div>

              {promosData?.map((item, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div
                    className={`promo-one-single-web shadow-lg webbuilder-choose-cards curr-pointer ${
                      index === expandedIndex
                        ? "promo-one-single-web-orange"
                        : ""
                    }`}
                    onClick={(event) => handleClick(index, event)}
                  >
                    <img
                      src={item?.icon}
                      alt="promo"
                      className="mb-4"
                      width="65"
                    />
                    <h5>{item?.title}</h5>

                    <p className="mb-0">
                      {expandedIndex === index
                        ? item?.content
                        : truncateText(item?.content, 133)}
                    </p>
                    {item?.content?.length > 133 && (
                      <a
                        className="icon-link accent-web-bg webbuilder-cards-icon"
                        onClick={(event) => handleClick(index, event)}
                      >
                        {expandedIndex !== index ? (
                          <span className="ti-angle-double-right"></span>
                        ) : (
                          <span className="ti-angle-double-left"></span>
                        )}
                        <div className="webbuilder-cards-text">
                          {expandedIndex === index
                            ? " Read Less"
                            : " Read More"}
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section
          id="features"
          className="feature-section ptb-100 gray-light-bg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2 className="web">Web Builder Features</h2>
                  <p className="text-muted para-desc mb-0 mx-auto">
                    Following are important features of ClinTa Web-Builder which
                    differentiates itself from other web builders and also from
                    general website developed in the market:
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="row">
                  {features?.map((feature, index) => (
                    <div className="col-md-4 col-12" key={index}>
                      <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                        <div className="features-single-content d-block overflow-hidden">
                          <h5 className="mb-3">
                            {feature?.icon} {feature?.title}
                          </h5>
                          <p className="text-justify">{feature?.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>


        <section
          id="process"
          className="work-process-section position-relative  ptb-100"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2 className="web">Advanced HR Features</h2>
                  <p>
                    ClinTa Web-Builder not only enables healthcare providers to
                    create professional websites effortlessly but also
                    integrates powerful HR features to streamline the
                    recruitment process.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-md-center justify-content-sm-center">
              <div className="col-md-12 col-lg-6">
                <div className="work-process-wrap">
                  <div className="process-single-item">
                    <div className="process-icon-item left-shape">
                      <div className="d-flex align-items-center">
                        {/*
                        <div className="process-icon mr-4">
                          <i className="fas fa-project-diagram color-primary"></i>
                        </div>
                        */}
                        <div className="process-content text-left">
                          <h5>1. Job Posting</h5>
                          <p>
                            - Publish job openings directly on your website. <br />
                            - Customize job listings with detailed descriptions
                            and requirements.
                          </p>
                        </div>
                      </div>
                      {/*}
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "
                        ></path>
                      </svg>
                      */}
                    </div>
                  </div>
                  <div className="process-single-item">
                    <div className="process-icon-item right-shape">
                      <div className="d-flex align-items-center">
                        {/*<div className="process-icon ml-4">
                          <i className="fas fa-puzzle-piece color-primary"></i>
                        </div>
                        */}
                        <div className="process-content text-right">
                          <h5>2. CV Management</h5>
                          <p>
                            - Receive applications directly within the admin
                            panel. <br />
                            - Filter and organize CVs based on specific
                            criteria of department (for which CV is submitted),
                            qualification, experience, gender and age group. <br />
                            - View CVs separately for interviewed and
                            non-interviewed candidates.
                          </p>
                        </div>
                      </div>
                      {/*
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                      </svg>
                      */}
                    </div>
                  </div>
                  <div className="process-single-item">
                    <div className="process-icon-item left-shape">
                      <div className="d-flex align-items-center">
                        {/*
                        <div className="process-icon mr-4">
                          <i className="fas fa-truck color-primary"></i>
                        </div>
                        */}
                        <div className="process-content text-left">
                          <h5>3. Interview Scheduling</h5>
                          <p>
                            - Schedule interviews seamlessly through the admin
                            panel. <br />
                            - Manage interview timings and notify
                            candidates directly from the website.
                          </p>
                        </div>
                      </div>
                      {/*
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "
                        ></path>
                      </svg>
                      */}
                    </div>
                  </div>
                  <div className="process-single-item">
                    <div className="process-icon-item right-shape">
                      <div className="d-flex align-items-center">
                        {/*
                        <div className="process-icon ml-4">
                          <i className="fas fa-puzzle-piece color-primary"></i>
                        </div>
                        */}
                        <div className="process-content text-right">
                          <h5>4. Candidate Categorization</h5>
                          <p>
                            - Mark interviewed candidates into four categories: A,
                            B, C and D. <br />
                            - Use the categorization for efficient
                            long listing and shortlisting.
                          </p>
                        </div>
                      </div>
                      {/*
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                      </svg>
                      */}
                    </div>
                  </div>

                  <div className="process-single-item">
                    <div className="process-icon-item left-shape mb-0">
                      <div className="d-flex align-items-center">
                        {/*
                        <div className="process-icon mr-4">
                          <i className="fas fa-truck color-primary"></i>
                        </div>
                        */}
                        <div className="process-content text-left">
                          <h5>5. Candidate Remarks and Finalization</h5>
                          <p>
                            - Add remarks and notes for each candidate for future
                            reference. <br />
                            - Communicate decisions to candidates
                            through auto email system or website’s integrated
                            messaging system.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="img-wrap">
                  <img
                    src="/assets/img/advanced-features.png"
                    alt="features"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative feature-section ptb-80 gray-light-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-12 col-lg-6">
                <div className="feature-contents section-heading">
                  <h2 className="web">Benefits of HR Module</h2>
                  <p>
                  Empower your HR team with an all-in-one solution that simplifies recruitment, enhances organization, and streamlines communication.
                  </p>
                  <ul className="dot-circle pt-2">
                    <li>
                    <b>Streamlined Recruitment Process:</b> Manage job postings, applications, and interviews all in one place. 
                    </li>
                    <li>
                    <b>Enhanced Organization:</b> Easily categorize and track candidates for better decision making. 
                    </li>
                    <li>
                    <b>Efficient Communication:</b> Directly communicate with candidates through the website, reducing delays and improving transparency.  
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="download-img">
                  <img
                    src="/assets/img/hr-benefits.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* <section className="bg-image ptb-100" image-overlay="8">
          <div
            className="background-image-wraper"
            style={{
              background: ` url('/assets/img/cta-bg.jpg')no-repeat center center / cover fixed`,
              opacity: "1",
            }}
          ></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-1 text-white">
                  <h2 className="text-white">Download Our Apps</h2>
                  <p>
                    Start working with that can provide everything you need to
                    generate awareness, drive traffic, connect. Efficiently
                    transform granular value with client-focused content.
                    Energistically redefine market.
                  </p>
                  <div className="action-btns">
                    <ul className="list-inline">
                      <li className="list-inline-item my-2">
                        <a
                          href="#"
                          className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                        >
                          <span className="fab fa-windows icon-size-sm mr-3"></span>
                          <div className="download-text text-left">
                            <small>Download form</small>
                            <h5 className="mb-0">Windows</h5>
                          </div>
                        </a>
                      </li>
                      <li className="list-inline-item my-2">
                        <a
                          href="#"
                          className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                        >
                          <span className="fab fa-apple icon-size-sm mr-3"></span>
                          <div className="download-text text-left">
                            <small>Download form</small>
                            <h5 className="mb-0">App Store</h5>
                          </div>
                        </a>
                      </li>
                      <li className="list-inline-item my-2">
                        <a
                          href="#"
                          className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                        >
                          <span className="fab fa-google-play icon-size-sm mr-3"></span>
                          <div className="download-text text-left">
                            <small>Download form</small>
                            <h5 className="mb-0">Google Play</h5>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <PricingTableSecond product="builder" /> */}
        {/* <section id="pricing" className="pricing-section ptb-100 ">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 col-lg-8">
                                <div className="section-heading text-center mb-4">
                                    <h2>Our Flexible Price</h2>
                                    <p>
                                        Professional hosting at an affordable price. Distinctively recaptiualize principle-centered
                                        core competencies through client-centered
                                        core competencies.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-md-center justify-content-center">
                            <div className="col-12">
                                <div className="d-flex justify-content-center text-center">
                                    <label className="pricing-switch-wrap">
                                        <span className="beforeinput year-switch text-success">
                                            Monthly
                                        </span>
                                        <input type="checkbox" className="d-none" id="js-contcheckbox" />
                                        <span className="switch-icon"></span>
                                        <span className="afterinput year-switch">
                                            Yearly
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8">
                                <div className="text-center bg-white single-pricing-pack mt-4">
                                    <div className="price-img pt-4">
                                        <img src="assets/img/priching-img-1.png" alt="price" width="120" className="img-fluid" />
                                    </div>
                                    <div className="py-4 border-0 pricing-header">
                                        <div className="price text-center mb-0 monthly-price color-secondary" style={{ display: "block" }}>$19<span>.99</span></div>
                                        <div className="price text-center mb-0 yearly-price color-secondary" style={{ display: "none" }}>$69<span>.99</span></div>
                                    </div>
                                    <div className="price-name">
                                        <h5 className="mb-0">Standard</h5>
                                    </div>
                                    <div className="pricing-content">
                                        <ul className="list-unstyled mb-4 pricing-feature-list">
                                            <li><span>Limited</span> access for a month</li>
                                            <li><span>15</span> customize sub page</li>
                                            <li className="text-deem"><span>105</span> disk space</li>
                                            <li className="text-deem"><span>3</span> domain access</li>
                                            <li className="text-deem">24/7 phone support</li>
                                        </ul>
                                        <a href="#" className="btn btn-outline-brand-02 btn-rounded mb-3" target="_blank">Purchase now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8">
                                <div className="popular-price bg-white text-center single-pricing-pack mt-4">
                                    <div className="price-img pt-4">
                                        <img src="assets/img/priching-img-2.png" alt="price" width="120" className="img-fluid" />
                                    </div>
                                    <div className="py-4 border-0 pricing-header">
                                        <div className="price text-center mb-0 monthly-price color-secondary" style={{ display: "block" }}>$49<span>.99</span></div>
                                        <div className="price text-center mb-0 yearly-price color-secondary" style={{ display: "none" }}>$159<span>.99</span></div>
                                    </div>
                                    <div className="price-name">
                                        <h5 className="mb-0">Premium</h5>
                                    </div>
                                    <div className="pricing-content">
                                        <ul className="list-unstyled mb-4 pricing-feature-list">
                                            <li><span>Unlimited</span> access for a month</li>
                                            <li><span>25</span> customize sub page</li>
                                            <li><span>150</span> disk space</li>
                                            <li className="text-deem"><span>5</span> domain access</li>
                                            <li className="text-deem">24/7 phone support</li>
                                        </ul>
                                        <a href="#" className="btn btn-brand-02 btn-rounded mb-3" target="_blank">Purchase now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8">
                                <div className="text-center bg-white single-pricing-pack mt-4">
                                    <div className="price-img pt-4">
                                        <img src="assets/img/priching-img-3.png" alt="price" width="120" className="img-fluid" />
                                    </div>
                                    <div className="py-4 border-0 pricing-header">
                                        <div className="price text-center mb-0 monthly-price color-secondary" style={{ display: "block" }}>$69<span>.99</span></div>
                                        <div className="price text-center mb-0 yearly-price color-secondary" style={{ display: "none" }}>$259<span>.99</span></div>
                                    </div>
                                    <div className="price-name">
                                        <h5 className="mb-0">Unlimited</h5>
                                    </div>
                                    <div className="pricing-content">
                                        <ul className="list-unstyled mb-4 pricing-feature-list">
                                            <li><span>Limited</span> access for a month</li>
                                            <li><span>15</span> customize sub page</li>
                                            <li><span>120</span> disk space</li>
                                            <li><span>5</span> domain access</li>
                                            <li>24/7 phone support</li>
                                        </ul>
                                        <a href="#" className="btn btn-outline-brand-02 btn-rounded mb-3" target="_blank">Purchase now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="support-cta text-center mt-5">
                                    <h5 className="mb-1"><span className="ti-headphone-alt color-primary mr-3"></span>We're Here to Help You
                                    </h5>
                                    <p>Have some questions? <a href="#">Chat with us now</a>, or <a href="#">send us an email</a> to
                                        get in touch.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
        {/* <section className="counter-section gradient-bg ptb-40">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="text-white p-2 count-data text-center my-3">
                  <span className="fas fa-users icon-size-lg mb-2"></span>
                  <h3 className="count-number mb-1 text-white font-weight-bolder">
                    21023
                  </h3>
                  <span>Customers</span>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="text-white p-2 count-data text-center my-3">
                  <span className="fas fa-cloud-download-alt icon-size-lg mb-2"></span>
                  <h3 className="count-number mb-1 text-white font-weight-bolder">
                    44023
                  </h3>
                  <span>Downloads</span>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="text-white p-2 count-data text-center my-3">
                  <span className="fas fa-smile icon-size-lg mb-2"></span>
                  <h3 className="count-number mb-1 text-white font-weight-bolder">
                    35023
                  </h3>
                  <span>Satisfied</span>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="text-white p-2 count-data text-center my-3">
                  <span className="fas fa-mug-hot icon-size-lg mb-2"></span>
                  <h3 className="count-number mb-1 text-white font-weight-bolder">
                    2323
                  </h3>
                  <span>Cup of Coffee</span>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="team-two-section ptb-100 ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center">
                  <h2>Our Team Members</h2>
                  <p>
                    Authoritatively mesh intuitive paradigms vis-a-vis
                    goal-oriented partnerships. Continually extend open-source
                    outside the box thinking after focused catalysts.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="assets/img/team/team-member-1.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Richard Ford</h5>
                    <span>Instructor of Mathematics</span>
                    <p className="mt-3">
                      Authoritatively engage leading-edge processes tactical
                      capital{" "}
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="assets/img/team/team-member-2.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Kely Roy</h5>
                    <span>Lead Designer</span>
                    <p className="mt-3">
                      Monotonectally engage sticky collaborative markets
                      synergistically
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="assets/img/team/team-member-3.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Gerald Nichols</h5>
                    <span>Managing Director</span>
                    <p className="mt-3">
                      Assertively procrastinate standardized whereas technically
                      sound
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="assets/img/team/team-member-4.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Gerald Nichols</h5>
                    <span>Team Manager</span>
                    <p className="mt-3">
                      Synergistically actualize out the-box technologies before
                      parallel process
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section id="faq" className="ptb-100 white-light-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12">
                <div className="section-heading text-center mb-5">
                  <h2 className="web">Frequently Asked Queries</h2>
                  {/* <p>
                    Efficiently productivate reliable paradigms before
                    ubiquitous models. Continually utilize frictionless
                    expertise whereas tactical relationships. Still have
                    questions? Contact us
                  </p> */}
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-12">
                <div className="row">
                  {faqData?.map((item, index) => (
                    <div className="col-md-6">
                      <div id="accordion" className="accordion faq-wrap">
                        <div className="card my-3" key={index}>
                          <div
                            className={`card-header ${
                              activeAccordion === index
                                ? "web-color"
                                : "collapsed"
                            }`}
                            onClick={() => toggleAccordion(index)}
                          >
                            <h6 className="mb-0 d-inline-block webbuilder-faq-title">
                              {item.question}
                            </h6>
                          </div>
                          <div
                            className={`collapse ${
                              activeAccordion === index ? "show" : ""
                            }`}
                            aria-labelledby={`heading${index}`}
                            data-parent="#accordion"
                          >
                            <div className="card-body white-bg">
                              <p>{item.answer}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="position-relative gradient-bg ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-5 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                <div className="testimonial-heading text-white">
                  <h2 className="text-white">
                    What Our Client Say About Apdash
                  </h2>
                  <p>
                    Collaboratively actualize excellent schemas without
                    effective models. Synergistically engineer functionalized
                    applications rather than backend e-commerce.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="testimonial-content-wrap">
                  <div className="owl-carousel owl-theme client-testimonial-1 dot-indicator testimonial-shape">
                    <div className="item">
                      <div className="testimonial-quote-wrap">
                        <div className="media author-info mb-3">
                          <div className="author-img mr-3">
                            <img
                              src="assets/img/client/1.jpg"
                              alt="client"
                              className="img-fluid"
                            />
                          </div>
                          <div className="media-body text-white">
                            <h5 className="mb-0 text-white">John Charles</h5>
                            <span>Head Of Admin</span>
                          </div>
                          <i className="fas fa-quote-right text-white"></i>
                        </div>
                        <div className="client-say text-white">
                          <p>
                            Interactively optimize fully researched expertise
                            vis-a-vis plug-and-play relationships. Intrinsicly
                            develop viral core competencies for fully tested
                            customer service. Enthusiastically create
                            next-generation growth strategies and.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="testimonial-quote-wrap">
                        <div className="media author-info mb-3">
                          <div className="author-img mr-3">
                            <img
                              src="assets/img/client/2.jpg"
                              alt="client"
                              className="img-fluid"
                            />
                          </div>
                          <div className="media-body text-white">
                            <h5 className="mb-0 text-white">Arabella Ora</h5>
                            <span>HR Manager</span>
                          </div>
                          <i className="fas fa-quote-right text-white"></i>
                        </div>
                        <div className="client-say text-white">
                          <p>
                            Rapidiously develop user friendly growth strategies
                            after extensive initiatives. Conveniently grow
                            innovative benefits through fully tested
                            deliverables. Rapidiously utilize focused platforms
                            through end-to-end schemas.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="testimonial-quote-wrap">
                        <div className="media author-info mb-3">
                          <div className="author-img mr-3">
                            <img
                              src="assets/img/client/3.jpg"
                              alt="client"
                              className="img-fluid"
                            />
                          </div>
                          <div className="media-body text-white">
                            <h5 className="mb-0 text-white">Jeremy Jere</h5>
                            <span>Team Leader</span>
                          </div>
                          <i className="fas fa-quote-right text-white"></i>
                        </div>
                        <div className="client-say text-white">
                          <p>
                            Objectively synthesize client-centered e-tailers for
                            maintainable channels. Holisticly administrate
                            customer directed vortals whereas tactical
                            functionalities. Energistically monetize reliable
                            imperatives through client-centric.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="testimonial-quote-wrap">
                        <div className="media author-info mb-3">
                          <div className="author-img mr-3">
                            <img
                              src="assets/img/client/4.jpg"
                              alt="client"
                              className="img-fluid"
                            />
                          </div>
                          <div className="media-body text-white">
                            <h5 className="mb-0 text-white">John Charles</h5>
                            <span>Marketing Head</span>
                          </div>
                          <i className="fas fa-quote-right text-white"></i>
                        </div>
                        <div className="client-say text-white">
                          <p>
                            Enthusiastically innovate B2C data without
                            clicks-and-mortar convergence. Monotonectally
                            deliver compelling testing procedures vis-a-vis B2B
                            testing procedures. Competently evisculate
                            integrated resources whereas global.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="our-blog-section ptb-100 gray-light-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center">
                  <h2>Our Latest News</h2>
                  <p>
                    Efficiently matrix robust total linkage after market
                    positioning bandwidth. Holisticly restore B2B materials
                    rather than brand flexible paradigms vis-a-vis
                    mission-critical e-commerce.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-4">
                <div className="single-blog-card card border-0 shadow-sm mt-4">
                  <div className="blog-img position-relative">
                    <img
                      src="assets/img/blog/1.jpg"
                      className="card-img-top"
                      alt="blog"
                    />
                    <div className="meta-date">
                      <strong>24</strong>
                      <small>Apr</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="post-meta mb-2">
                      <ul className="list-inline meta-list">
                        <li className="list-inline-item">
                          <i className="fas fa-heart mr-2"></i>
                          <span>45</span>
                          Comments
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-share-alt mr-2"></i>
                          <span>10</span>
                          Share
                        </li>
                      </ul>
                    </div>
                    <h3 className="h5 mb-2 card-title">
                      <a href="#">Appropriately productize fully</a>
                    </h3>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk.
                    </p>
                    <a href="#" className="detail-link">
                      Read more <span className="ti-arrow-right"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="single-blog-card card border-0 shadow-sm mt-4">
                  <div className="blog-img position-relative">
                    <img
                      src="assets/img/blog/2.jpg"
                      className="card-img-top"
                      alt="blog"
                    />
                    <div className="meta-date">
                      <strong>24</strong>
                      <small>Apr</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="post-meta mb-2">
                      <ul className="list-inline meta-list">
                        <li className="list-inline-item">
                          <i className="fas fa-heart mr-2"></i>
                          <span>45</span>
                          Comments
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-share-alt mr-2"></i>
                          <span>10</span>
                          Share
                        </li>
                      </ul>
                    </div>
                    <h3 className="h5 mb-2 card-title">
                      <a href="#">Quickly formulate backend</a>
                    </h3>
                    <p className="card-text">
                      Synergistically engage effective ROI after customer
                      directed partnerships.
                    </p>
                    <a href="#" className="detail-link">
                      Read more <span className="ti-arrow-right"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="single-blog-card card border-0 shadow-sm mt-4">
                  <div className="blog-img position-relative">
                    <img
                      src="assets/img/blog/3.jpg"
                      className="card-img-top"
                      alt="blog"
                    />
                    <div className="meta-date">
                      <strong>24</strong>
                      <small>Apr</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="post-meta mb-2">
                      <ul className="list-inline meta-list">
                        <li className="list-inline-item">
                          <i className="fas fa-heart mr-2"></i>
                          <span>45</span>
                          Comments
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-share-alt mr-2"></i>
                          <span>10</span>
                          Share
                        </li>
                      </ul>
                    </div>
                    <h3 className="h5 mb-2 card-title">
                      <a href="#">Objectively extend extensive</a>
                    </h3>
                    <p className="card-text">
                      Holisticly mesh open-source leadership rather than
                      proactive users.{" "}
                    </p>
                    <a href="#" className="detail-link">
                      Read more <span className="ti-arrow-right"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section id="contact" className="contact-us-section ptb-100">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-12 pb-3 message-box d-none">
                <div className="alert alert-danger"></div>
              </div>
              <div className="col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                <div className="contact-us-form gray-light-bg rounded p-5">
                  <h4>Ready to get started?</h4>
                  <form
                    action="#"
                    method="POST"
                    id="contactForm"
                    className="contact-us-form"
                  >
                    <div className="form-row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter name"
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter email"
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            rows="7"
                            cols="25"
                            placeholder="Message"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-3">
                        <button
                          type="submit"
                          className="btn btn-brand-02"
                          id="btnContactUs"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="contact-us-content">
                  <h2>Looking for a excellent Business idea?</h2>
                  <p className="lead">
                    Give us a call or drop by anytime, we endeavour to answer
                    all enquiries within 24 hours on business days.
                  </p>

                  <a
                    href="#"
                    className="btn btn-outline-brand-01 align-items-center"
                  >
                    Get Directions <span className="ti-arrow-right pl-2"></span>
                  </a>

                  <hr className="my-5" />

                  <ul className="contact-info-list">
                    <li className="d-flex pb-3">
                      <div className="contact-icon mr-3">
                        <span className="fas fa-location-arrow color-primary rounded-circle p-3"></span>
                      </div>
                      <div className="contact-text">
                        <h5 className="mb-1">Company Location</h5>
                        <p>100 Yellow House, Mn Factory, United State, 13420</p>
                      </div>
                    </li>
                    <li className="d-flex pb-3">
                      <div className="contact-icon mr-3">
                        <span className="fas fa-envelope color-primary rounded-circle p-3"></span>
                      </div>
                      <div className="contact-text">
                        <h5 className="mb-1">Email Address</h5>
                        <p>hello@yourdomain.com</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>

      <Newsletter />
      <Footer />
    </>
  );
}

export default WebBuilder;
