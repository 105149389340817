import React from 'react';

function About2() {
    return (
            <section id="about" className="ptb-100 gray-light-bg">
                <div className="container">
                    <div className="section-heading text-center mb-2">
                        <h2>Operational Reliability and Efficiency</h2>
                        <p>We prioritize reliability, minimizing downtime and maximizing
                            productivity for healthcare providers nationwide. Our all software programs are:
                        </p>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12 col-lg-6">
                            <div className="feature-contents section-heading">
                                <ul className="check-list-wrap">
                                    <li><b>Objective Driven:</b> Our software range is meticulously designed to align with your goals & objectives, ensuring every feature serves your needs.</li>
                                    <li><b>Comprehensive:</b> Our software offers a comprehensive solution, addressing every aspect of your requirements with no gaps in features.</li>
                                    <li><b>Scalable:</b> The number of features can be adjusted based on the client's business size and budget to effectively meet diverse customer needs.</li>
                                    <li><b>Reliable:</b> Trust is paramount, and our software consistently delivers reliable results, ensuring stability and consistency in every use case.</li>
                                    <li><b>User Friendly:</b> Designed with user-friendly interfaces and workflows, it prioritizes user-comfort, ensuring easy navigation and effective use.</li>
                                    <li><b>Customer Support:</b> With responsive support, we're committed to assisting you at every step to ensure a satisfying experience.</li> 
                                </ul>

                                {/*<div className="action-btns mt-3">*/}
                                {/*    <a href="#" className="btn btn-brand-02 mr-3">Get Start Now</a>*/}
                                {/*    <a href="#" className="btn btn-outline-brand-02">Learn More</a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="mask-image">
                                <img src="/assets/img/home_new.png" className="img-fluid" alt="about"/>
                                {/*<div className="item-icon video-promo-content">*/}
                                {/*    <a href="https://www.youtube.com/watch?v=9No-FiEInLA"*/}
                                {/*       className="popup-youtube video-play-icon text-center m-auto"><span*/}
                                {/*        className="ti-control-play"></span> </a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}

export default About2;
