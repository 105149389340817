import React from 'react';

function Features() {
    return (
        <section id="features" className="feature-section ptb-100">
            <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-10">
                            <div className="section-heading text-center mb-5">
                                <h2>D-RIGHT’S Critical Success Factors</h2>
                                <p>These factors enabled us not only to deliver exceptional solutions but also ensured
                                    our client satisfaction, project success, and long-term partnerships.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/icon-2.svg" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">CREATIVE APPROACH  </h5>
                                            <p>D-RIGHT consistently delivers innovative solutions leveraging cutting-edge technology to address clients' needs. We stay ahead of industry trends and offer creative approaches to solving complex problems.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/icon-1.svg" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">TALENT DEVELOPMENT </h5>
                                            <p>D-RIGHT consists of a team of skilled professionals with expertise in
                                                various technologies and domains. We invest in talent development,
                                                certifications, and ongoing training to ensure our team equipped with
                                                the latest advancements.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/ClearCommunication.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">CLEAR COMMUNICATION </h5>
                                            <p>D-RIGHT maintains clear and transparent communication with clients
                                                irrespective of the business volume. We listen to client requirements,
                                                provide regular updates, and promptly address any concerns or issues
                                                that arise.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5 d-none d-sm-none d-md-block d-lg-block">
                            <div className="position-relative pb-md-5 py-lg-0 mb-5">
                                <img alt="Image placeholder" src="/assets/img/mobile.png"
                                    className="img-center img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/DataSecurity.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">DATA SECURITY</h5>
                                            <p>D-RIGHT prioritizes robust security measures to protect clients' data and
                                                systems. We adhere to industry best practices, implement encryption,
                                                access controls, and conduct regular security audits to mitigate
                                                risks.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/icon/CustomerSatisfaction.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">CUSTOMER SATISFACTION </h5>
                                            <p>D-RIGHT prioritizes customer satisfaction and builds long-term
                                                relationships by focusing on understanding clients' needs, providing
                                                solutions, and offering exceptional support throughout the
                                                engagement.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src="/assets/img/ResearchandDevelopment.png" alt="app icon" width="50"
                                            className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2">RESEARCH & DEVELOPMENT </h5>
                                            <p>D-RIGHT developed a culture of continuous improvement, encouraging employees
                                                to seek opportunities for learning, growth, and
                                                innovation. We invest in research and development, in our products and
                                                services.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default Features;
