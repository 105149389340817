import React from "react";
import Header from "../Components/Header";
import Newsletter from "../Components/Newsletter";
import Footer from "../Components/Footer";
import { Link, useParams } from "react-router-dom";
import TeamData from "../Pages/TeamData.json";

function TeamDetailPage() {
  const { slug } = useParams(); // Get the slug from the URL

  const findTeamMemberBySlug = (slug) => {

    const leadershipMember = TeamData.leadership_team?.team_member_detail?.find(
      (member) => member.slug === slug
    );
  
    const operationMember = TeamData.operation_team?.team_member_detail?.find(
      (member) => member.slug === slug
    );
  
    if (leadershipMember) {
      return leadershipMember; 
    } else if (operationMember) {
      return operationMember; 
    }
  
    
    return null;
  };

  const teamMember = findTeamMemberBySlug(slug);


  console.log("teamMember", teamMember);

  return (
    <>
      <Header />
      <div className="main">
        <section
          className="page-header-section ptb-100 bg-image"
          image-overlay="8"
        >
          <div
            className="background-image-wraper"
            style={{
              background: `url('/assets/img/hero-bg-1.jpg')`,
              opacity: "1",
            }}
          ></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-9 col-lg-7">
                <div className="page-header-content text-white pt-4">
                  <h1 className="text-white mb-0">Team Single</h1>
                  <p className="lead">
                    Rapidiously deploy world-class platforms whereas
                    collaborative interfaces. Phosfluorescently facilitate
                    corporate innovation via excellent web <services></services>{" "}
                    technically sound.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="breadcrumb-bar gray-light-bg border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="custom-breadcrumb">
                  <ol className="breadcrumb pl-0 mb-0 bg-transparent">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/teams">Team List</Link>
                    </li>
                    <li className="breadcrumb-item active">Team Single</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="team-single-section ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-sm-12 col-lg-6">
                <div className="team-single-img">
                  <div
                    className="team-detail-image img-fluid m-auto"
                    style={{
                      backgroundImage: `url(${teamMember?.image})`,
                    }}
                    alt="member"
                  ></div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-lg-6">
                <div className="team-single-text">
                  <div className="team-name mb-4">
                    <h4 className="mb-1">{teamMember?.name}</h4>
                    <span>{teamMember?.role || "--"}</span>
                  </div>

                  <div className="text-content mt-20">
                    <p>{teamMember?.description || "--"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6 col-sm-6 col-12">
                <div className="section-heading">
                  <h5>Activities And Skills</h5>
                  <div className="section-heading-line-left"></div>
                </div>
                <ul className="check-list-wrap pt-3">
                  {teamMember?.activities_skills?.map((data, index) => (
                    <li key={index}>
                      <strong>{data.heading}</strong> – {data.desc}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <div className="section-heading mt-40">
                  <h5>Professional Skills</h5>
                  <div className="section-heading-line-left"></div>
                </div>
                <div className="skill-content-wrap">
                  <p>
                    Holisticly whiteboard low-risk high-yield e-services
                    vis-a-vis user-centric data. Competently benchmark
                    communities whereas interactive.
                  </p>
                  {teamMember?.professional_skills?.map((data, index) => (
                    <div key={index} className="progress-item">
                      <div className="progress-title">
                        <h6>
                          {data.heading}
                          <span className="float-right">
                            <span className="progress-number">{data?.percentage}</span>
                          </span>
                        </h6>
                      </div>
                      <div className="progress">
                        <span style={{ width: data?.percentage }}>
                          <span className="progress-line"></span>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Newsletter />
      <Footer />
    </>
  );
}

export default TeamDetailPage;
