import React from "react";
import Header from "../Components/Header";
import TeamData from "./TeamData.json";
import { Link } from "react-router-dom";

const leadershipTeam = [
  {
    name: "Haadiyah Haque",
    role: "CEO",
    imageUrl: "/assets/img/dumy-person.jpg",
  },
  {
    name: "Arooba Amin Khan",
    role: "Executive Director",
    imageUrl: "/assets/img/dumy-person.jpg",
  },
  {
    name: "Hamdah Haque",
    role: "Creative Director",
    imageUrl: "/assets/img/dumy-person.jpg",
  },
];

function TeamPage() {
  console.log("TeamData", TeamData);
  return (
    <>
      <Header />
      <div className="main">
        <section
          className="page-header-section ptb-100 bg-image"
          image-overlay="7"
        >
          <div
            className="background-image-wraper"
            style={{
              background: `url('assets/img/team-banner.jpg')`,
              opacity: "1",
            }}
          ></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-9 col-lg-7">
                <div className="page-header-content text-white pt-4">
                  <h1 className="text-white mb-0">Team</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="breadcrumb-bar gray-light-bg border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="custom-breadcrumb">
                  <ol className="breadcrumb pl-0 mb-0 bg-transparent">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Team</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="team-two-section ptb-100">
          <div className="container">
            <h1 className="h1 text-black mb-0 mt-2">Leadership Team</h1>
            <p className="p">
              The working team, responsible for the day-to-day operations and
              implementation of the project, can be titled the Operations Team.
              This segment encompasses all individuals actively involved in
              executing tasks and achieving objectives.
            </p>

            <div className="row">
              {TeamData.leadership_team.team_members.map((member, index) => (
                <div className="col-md-6 col-lg-4" key={index}>
                  <Link to={`/team/detail/${member?.slug}`}>
                    {" "}
                    <div className="single-team-wrap bg-white text-center border rounded p-4 my-3 team-card-size">
                      <div
                        className="team-profile-image img-fluid m-auto"
                        style={{
                          backgroundImage: `url(${member.image})`,
                        }}
                      ></div>
                      <div className="team-content">
                        <div>
                          <h4 className="mb-2 mt-3">{member.name}</h4>
                          <span
                            style={{ color: "#696969" }}
                          >{`(${member.role})`}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="container ptb-100">
            <h1 className="h1 text-black mb-0 mt-2">Operation Team</h1>
            <p className="p">
              The working team, responsible for the day-to-day operations and
              implementation of the project, can be titled the Operations Team.
              This segment encompasses all individuals actively involved in
              executing tasks and achieving objectives.
            </p>

            <div className="row">
              {TeamData.operation_team.team_members.map((member, index) => (
                <div className="col-md-6 col-lg-4" key={index}>
                  <Link to={`/team/detail/${member?.slug}`}>
                    {" "}
                    <div className="single-team-wrap bg-white text-center border rounded p-4 my-3 team-card-size">
                      <div
                        className="team-profile-image img-fluid m-auto"
                        style={{
                          backgroundImage: `url(${member.image})`,
                        }}
                      ></div>
                      <div className="team-content">
                        <div>
                          <h4 className="mb-2 mt-3">{member.name}</h4>
                          <span
                            style={{ color: "#696969" }}
                          >{`(${member.role})`}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default TeamPage;
