import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";

function LatestBlogs() {
  // const [expandedIndex, setExpandedIndex] = useState(null);

  // const toggleExpandReview = (index) => {
  //     setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  // };

  // const truncateText = (text, maxLength) => {
  //     if (text.length > maxLength) {
  //         return text.slice(0, maxLength) + "...";
  //     }
  //     return text;
  // };

  const [modalShow, setModalShow] = useState(false);
  const [currentBlog, setCurrentBlog] = useState(null);

  const handleReadMore = (blog) => {
    setCurrentBlog(blog);
    setModalShow(true);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const blogs = [
    {
      heading: "Basic Edition",
      img: "/assets/img/blog/Basic_edition.png",
      detail: [
        "Perfect for smaller labs, the Basic Edition of LARIS offers essential modules for reception, phlebotomists, technologists, pathologists, and admin.",
        "It provides a user-friendly interface and comprehensive features within each module to streamline lab operations and enhance productivity.",
        "Ideal for managing patient data, specimen tracking, and test results, the Basic Edition ensures efficient workflow and accurate reporting.",
        "Designed for simplicity and ease of use, this edition empowers labs to meet their basic operational needs effectively.",
        "With customizable settings and intuitive tools, the Basic Edition is the foundation for efficient laboratory management.",
      ],
    },
    {
      heading: "Advanced Edition",
      img: "/assets/img/blog/advance_edition.png",
      detail: [
        "Building upon the features of the Basic Edition, the Advanced Edition of LARIS offers additional modules for procurement and accounts management.",
        "It provides comprehensive solutions for purchasing, invoicing, and financial management, empowering labs to optimize financial processes.",
        "With enhanced functionalities, such as budgeting, vendor management, and expense tracking, the Advanced Edition enables labs to achieve greater efficiency and cost-effectiveness.",
        "Designed for mid-sized labs with more complex operational requirements, this edition provides robust tools for streamlined procurement and financial management.",
        "By integrating procurement and accounting functionalities with core laboratory operations, the Advanced Edition offers a comprehensive solution for lab management.",
      ],
    },
    {
      heading: "Premium Edition",
      img: "/assets/img/blog/premium_edition.png",
      detail: [
        "The Premium Edition of LARIS represents the peak of laboratory management software, offering advanced features and comprehensive modules tailored for larger labs.",
        "In addition to the modules available in the Advanced Edition, the Premium Edition includes two new modules: inventory control and HR management.",
        "It provides extensive capabilities for managing lab inventory, tracking stock levels, and optimizing supply chain processes for improved efficiency and cost control.",
        "With integrated HR management functionalities, including employee records, payroll processing, and performance management, the Premium Edition offers a complete solution for workforce management.",
        "The Premium Edition also includes a complimentary website integrated with LARIS, offering patient appointment scheduling, report access, and a dedicated patient portal for enhanced patient engagement and satisfaction. ",
      ],
    },
  ];

  return (
    <div>
      <section className="our-blog-section ptb-100 gray-light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading text-center">
                <h2 className="laris">Tailored Pathology Lab Solutions</h2>
                <p>Explore LARIS software's three editions, each designed to meet the unique needs of pathology labs. From essential to advanced features, find the right edition to enhance your lab's efficiency, streamline operations, and optimize pathology management.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {blogs.map((data, index) => (
              <div className="col-md-6 col-lg-4" key={index}>
                <div className="single-blog-card-laris card border-0 shadow-sm mt-4">
                  <div className="blog-img position-relative">
                    <img
                      // src="/assets/img/blog/1.jpg"
                      src={data?.img}
                      className="card-img-top"
                      alt="blog"
                    />
                    {/* <div className="meta-date">
                      <strong>24</strong>
                      <small>Apr</small>
                    </div> */}
                  </div>
                  <div className="card-body">
                    {/* <div className="post-meta mb-2">
                      <ul className="list-inline meta-list">
                        <li className="list-inline-item">
                          <i className="fas fa-heart mr-2"></i>
                          <span>45</span> Comments
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-share-alt mr-2"></i>
                          <span>10</span> Share
                        </li>
                      </ul>
                    </div> */}
                    <h2 className="h5 mb-1 card-title">
                      <a className="web">{data?.heading}</a>
                    </h2>
                    <p className="card-text text-justify mb-0">
                      {truncateText(data?.detail[0], 87)}
                    </p>
                    {/* {data?.detail.length > 87 && ( */}
                    <a
                      className="detail-link fw-bold curr-pointer"
                      onClick={() => handleReadMore(data)}
                    >
                      Read More
                      <span className="ti-arrow-right ml-2"></span>
                    </a>
                    {/* )} */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* <div className="col-md-6 col-lg-4">
                            <div className="single-blog-card card border-0 shadow-sm mt-4">
                                <div className="blog-img position-relative">
                                    <img src="/assets/img/blog/2.jpg" className="card-img-top" alt="blog" />
                                    <div className="meta-date">
                                        <strong>24</strong>
                                        <small>Apr</small>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="post-meta mb-2">
                                        <ul className="list-inline meta-list">
                                            <li className="list-inline-item"><i
                                                className="fas fa-heart mr-2"></i><span>45</span>
                                                Comments
                                            </li>
                                            <li className="list-inline-item"><i
                                                className="fas fa-share-alt mr-2"></i><span>10</span>
                                                Share
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 className="h5 mb-2 card-title"><a href="#">Quickly formulate backend</a>
                                    </h3>
                                    <p className="card-text">Synergistically engage effective ROI after customer
                                        directed
                                        partnerships.</p>
                                    <a href="#" className="detail-link">Read more <span
                                        className="ti-arrow-right"></span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="single-blog-card card border-0 shadow-sm mt-4">
                                <div className="blog-img position-relative">
                                    <img src="/assets/img/blog/3.jpg" className="card-img-top" alt="blog" />
                                    <div className="meta-date">
                                        <strong>24</strong>
                                        <small>Apr</small>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="post-meta mb-2">
                                        <ul className="list-inline meta-list">
                                            <li className="list-inline-item"><i
                                                className="fas fa-heart mr-2"></i><span>45</span>
                                                Comments
                                            </li>
                                            <li className="list-inline-item"><i
                                                className="fas fa-share-alt mr-2"></i><span>10</span>
                                                Share
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 className="h5 mb-2 card-title"><a href="#">Objectively extend extensive</a>
                                    </h3>
                                    <p className="card-text">Holisticly mesh open-source leadership rather than
                                        proactive
                                        users. </p>
                                    <a href="#" className="detail-link">Read more <span
                                        className="ti-arrow-right"></span></a>
                                </div>
                            </div>
                        </div> */}

      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header>
          <Modal.Title>{currentBlog?.heading}</Modal.Title>
          <IoMdClose
            onClick={() => setModalShow(false)}
            style={{ cursor: "pointer", fontWeight: "bold", fontSize: "32px" }}
          />
        </Modal.Header>
        <Modal.Body>
          <ul className="laris_card_editions">
            {currentBlog?.detail?.map((item, index) => {
              return (
                <li className="mb-3" key={index}>
                  {item}
                </li>
              );
            })}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LatestBlogs;
